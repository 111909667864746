import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import BlogGrid from "../components/blog/blog-grid"

const BlogPage = ({ data }) => {
  const allposts = data.four.edges
  const restposts = data.rest.edges
  const treeimages = data.allSanityGallery.edges[0].node.treeSlides
  const beachimages = data.allSanityGallery.edges[0].node.beachSlides

  return (
    <>
      <SEO title="Blog" />
      <BlogGrid
        posts={allposts}
        rest={restposts}
        treeshoot={treeimages}
        beachshoot={beachimages}
      />
    </>
  )
}

export default BlogPage

export const query = graphql`
  {
    four: allSanityPost(
      filter: { status: { eq: "Published" } }
      sort: { order: DESC, fields: publishedAt }
      limit: 4
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          publishedAt
          _rawContent(resolveReferences: { maxDepth: 10 })
          status
          featuredImage {
            image {
              asset {
                fluid(maxWidth: 960) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
    rest: allSanityPost(
      filter: { status: { eq: "Published" } }
      sort: { order: DESC, fields: publishedAt }
      skip: 4
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          publishedAt
          _rawContent(resolveReferences: { maxDepth: 10 })
          status
          featuredImage {
            image {
              asset {
                fluid(maxWidth: 960) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
    allSanityGallery {
      edges {
        node {
          beachSlides {
            alt
            image {
              asset {
                fluid(maxWidth: 960) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          treeSlides {
            alt
            image {
              asset {
                fluid(maxWidth: 960) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
